<template>
  <a @click="handleDownload">
    <slot name="trigger" :downloading="downloading">
      <MIcon v-if="downloading" name="spinner-third" class="fa-spin" />
      <slot :downloading="downloading">
        {{ title }}
      </slot>
    </slot>
  </a>
</template>

<script>
import { attachmentDownloadApi } from '@data/attachment'
export default {
  name: 'FlotoDownloadLink',
  props: {
    url: { type: String, required: true },
    fileName: { type: String, required: true },
    title: { type: String, default: undefined },
  },
  data() {
    return {
      downloading: false,
    }
  },
  methods: {
    handleDownload() {
      if (this.downloading) {
        return
      }
      this.downloading = true
      return attachmentDownloadApi(this.url, this.fileName)
        .then(() => {
          this.downloading = false
        })
        .catch(() => {
          this.downloading = false
        })
    },
  },
}
</script>
